import React, {Component} from 'react';
import PropTypes from 'prop-types';


export default class Loader extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div id="loading">
                    <div id="loading-center">
                        <div id="loading-center-absolute">
                            <div className="object" id="object_four"></div>
                            <div className="object" id="object_three"></div>
                            <div className="object" id="object_two"></div>
                            <div className="object" id="object_one"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}