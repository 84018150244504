import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Ranking from "./Ranking";
import RankingSlideUpPanel from "./RankingSlideUpPanel";
import firebase from "./firebase";
import RankingPreLogin from "./RankingPreLogin";

const styles = theme => ({});


export default withStyles(styles)(
    class RankingComponent extends React.Component {

        static propTypes = {
            user: PropTypes.object,
        };

        constructor(props) {
            super(props);
            this.state = {
                login: false,
                user: {},
                userListener: '',
                invalidUser: false,
                name: '',
                lastName: '',
                id: '',
                shares: 0,
                email: '',
            };
        }

        componentDidMount() {

        }

        handleLogin = (email) =>{
            let db = firebase.firestore();
            let userListener = db.collection('users').where('campaign','==', 'demogetviral')
                .where('email', '==', email).onSnapshot(snap => {
                if (snap.size > 0){
                    snap.forEach(user => {
                        let userData = user.data();
                        userData.id = user.id;
                        this.setState({
                            login: true,
                            user: userData,
                            name: userData.name,
                            lastName: userData.lastName,
                            email: userData.email,
                            id: userData.id,
                            shares: userData.shareToRegister,
                        })
                    })
                }else {
                    this.setState({
                        invalidUser: true,
                    })
                }

            });
            this.setState({
                userListener: userListener,
            })
        };

        render() {
            const {classes} = this.props;
            return (
                <div>
                    {this.state.login && <Ranking  login={this.state.login} name={this.state.name} lastName={this.state.lastName}
                        id={this.state.id} shares={this.state.shares} email={this.state.email}/>}
                    {!this.state.login && <RankingPreLogin/>}
                    <RankingSlideUpPanel login={this.state.login} name={this.state.name} lastName={this.state.lastName}
                                         id={this.state.id} shares={this.state.shares} user={this.state.user} handleLogin={this.handleLogin}/>
                </div>
            )
        }
    })