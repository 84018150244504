import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import logo from "./images/getviralLogo.png";
import Lottie from 'react-lottie';
import * as animationData from './animations/677-trophy'
import './Ranking.css';
const styles = theme => ({});


export default withStyles(styles)(
    class RankingPreLogin extends React.Component {

        static propTypes = {
            user: PropTypes.object,
        };

        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {

        }

        render() {
            const {classes} = this.props;
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: animationData.default,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            };
            return (
                <div className={'rankingContainer'}>
                    <div  className={'logoContainer rankingLogoContainer'}>
                        <img className={'logoRanking'} src={logo}/>
                        <p className={'rankingPreP'}> {
                            '¡Ingresa tu email y fijate cuanto te falta para alcanzar la punta!'
                        } </p>
                    </div>

                    <Lottie options={defaultOptions}
                          />

                </div>
            )
        }
    })