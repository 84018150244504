import React from 'react';
import PropTypes from 'prop-types';
import Animated from "animated/lib/targets/react-dom";
import Interactable from "react-interactable/noNative";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import firebase from './firebase';
import mercadopago from 'mercadopago';
import { spring, AnimatedSwitch } from 'react-router-transition';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withRouter} from "react-router-dom";
import { Route } from "react-router-dom";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import SwipeableViews from 'react-swipeable-views';


function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `translateX(${styles.offset})`,

    };
}



function isDate18orMoreYearsOld(day, month, year) {
    return new Date(year+18, month-1, day) <= new Date();
}


function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
    return spring(val, {
        stiffness: 330,
        damping: 22,
    });
}

// child matches will...
const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
        opacity: 0,
        scale: 1.2,
    },
    // leave in a transparent, downscaled state
    atLeave: {
        opacity: bounce(0),
        scale: bounce(0.8),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
        opacity: bounce(1),
        scale: bounce(1),
    },
};


let posX = new Animated.Value(0);

let styles = {
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        overflow: "hidden",

    },
    drawerContainer: {
        width: '100vw',
        position: "absolute",
        left: 0,
        /* bottom: 0,*/
        overflow: "hidden",
        zIndex: 3000,
        height: '100vh'
        /* backgroundColor: '#3591FF',*/
    },
    content: {
        backgroundColor: "white",
        flexGrow: 1,
        position: "relative"
    },
    panelContainer: {
        width: window.innerWidth,
        height: 'auto',
        position: "relative",
        transform: 'translateY(calc(100% - 80px))',
        msTransform: 'translateY(-ms-calc(100% - 80px))',
        WebkitTransform: 'translateY(-webkit-calc(100% - 80px))',
        MozTransform: 'translateY(-ms-calc(100% - 80px))',
        OTransform: 'translateY(-o-calc(100% - 80px))',
    },

    leftPanel: {
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        maxWidth: '1000px',
        height: "auto",
        zIndex: 2000,
        flex: 1,
        position: "absolute",
        right: 0,
        left: 0,
        margin: 'auto',
        display: 'block',
        backgroundColor: '#C444FE',
    },
    successPanel: {
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        maxWidth: '1000px',
        height: "calc(100vh - 60px)",
        zIndex: 2000,
        flex: 1,
        position: "absolute",
        right: 0,
        left: 0,
        margin: 'auto',
        display: 'block',
        backgroundColor: '#C444FE',
    },
    toggle: {
        fontSize: 30,
    },
    inter: {
        position: 'fixed',
        bottom: 0,
        zIndex: 1400,
    }

};

export default withRouter( class RankingSlideUpPanel extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            panelOpen: false,
            panelHeight: 0,
            toggleStyle: {},
            email: '',
            error: false,
            value: 0,
            name: '',
            lastName: '',
            id: '',
            shares: '',
        };
    }






    handleChangeIndex = index => {

        const oldIndex = this.state.value;
        this.setState({
            value: index,
        }, () => {
            if (!this.props.login){
                this.setState({
                    value: 0,
                });
            }
            else if (index < oldIndex) {
                this.setState({
                    value: oldIndex,
                });
            }
        });
    };
    handleChange = name => (event) => {
        var context = this;
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };


    toggleRotation = (height) => {
        if (height < 80){
            height = 81;
        }
        return posX.interpolate({
            inputRange: [0, (80 - height) * -1],
            outputRange: [ "0deg", "180deg"]
        })
    };

    onLogin = () => {
        if (validateEmail(this.state.email)){
            this.props.handleLogin(this.state.email);
        }
        else {
            this.setState({
                error: true,
            })
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged = () =>  {
        console.log("ROUTE CHANGED");
        setTimeout(() => {
            console.log(this.panelRef.offsetHeight);
            this.setState({
                panelHeight: this.panelRef.offsetHeight,
                toggleStyle: {
                    ...styles.toggle,
                    transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                    WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                }

            })
        },1000);

    };


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.login){
            if (nextProps.login !== this.props.login) {
                setTimeout(() => {
                    this.setState({ value: 1 });
                    console.log(this.panelRef.offsetHeight);
                    this.setState({
                        panelHeight: this.panelRef.offsetHeight,
                        toggleStyle: {
                            ...styles.toggle,
                            transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                            WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                        }
                    });
                    this.toggleOpen()
                },500);
            }
        }
        if (nextProps.id !== this.props.id){
            this.setState({
                name: nextProps.name,
                lastName: nextProps.lastName,
                id: nextProps.id,
                shares: nextProps.shares
            })
        }
    }


    componentDidMount() {

        setTimeout(()=>{
            this.setState({
                panelHeight: this.panelRef.offsetHeight,
                toggleStyle: {
                    ...styles.toggle,
                    transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                    WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                }

            })
        },500);

        this.setState({
            panelHeight: this.panelRef.offsetHeight,
            toggleStyle: {
                ...styles.toggle,
                transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
            }

        })
    }

    toggleOpen = () => {
        if (this.state.panelOpen){
            this.componentRef.snapTo({index: 0});
            this.setState({panelOpen: false})
        } else {
            this.componentRef.snapTo({index: 1});
            this.setState({panelOpen: true})
        }
    };

    onNext = ()=> {
        if (this.state.value < 2){
            if (this.state.name && this.state.lastName){
                this.handleChangeIndex(2);
            }
            else {
                this.setState({
                    error: true,
                })
            }
        }

    };


    render() {
        const theme = createMuiTheme({
            palette: {
                /*  primary: {
                      // light: will be calculated from palette.primary.main,
                      main: '#151F2A',
                      // dark: will be calculated from palette.primary.main,
                      // contrastText: will be calculated to contrast with palette.primary.main
                      contrastText: '#EDAA00'
                  },*/
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#ffffff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#000000'
                },
                secondary: {
                    main: '#ff0013',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#ffffff'
                },
                error: {
                    main: '#fff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#ff0013'
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <MuiThemeProvider theme={theme}>
                <Interactable.View
                    ref={el => (this.componentRef = el)}
                    animatedValueY={posX}
                    boundaries={{top: 80 - this.state.panelHeight, bottom: 0, bounce: 1}}
                    snapPoints={[{ y: 0 }, { y: 80 -this.state.panelHeight }]}
                    verticalOnly={true}
                    style={styles.inter}
                >
                    <div style={styles.panelContainer}   >
                        <Paper className={'panel'} style={this.state.success ? styles.successPanel : styles.leftPanel} variant="contained" color="primary" ref={el => (this.panelRef = el)}>
                            <div onClick={this.toggleOpen}>
                                <Animated.div  style={this.state.toggleStyle}><i style={{color: '#ffffff'}} className={'ion-md-arrow-dropup'}/></Animated.div>
                                <p className={'startLoginPTitle'}> {
                                    this.state.value ?
                                        '🕹️ Seguí compartiendo' : '🕹️ Ingresa'
                                } </p>
                            </div>
                            <SwipeableViews
                                index={this.state.value}
                                onChangeIndex={this.handleChangeIndex}
                            >
                                <div>
                                    <p className={'startLoginPTitle'}> {
                                        'Ingresa el e-mail con el que te registraste'
                                    } </p>
                                    <TextField
                                        value={this.state.email}
                                        onChange={this.handleChange('email')}
                                        error={this.state.error}
                                        label="E-mail"
                                        type="email"
                                        className="fieldModal loginDetails"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            classes: {
                                                root: '',
                                                focused: 'inputCustomLogin',
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: 'inputCustomLogin',
                                                focused: 'inputCustomLogin',
                                                notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                error: 'errorInput',
                                            },
                                        }}
                                    />
                                    <Button onClick={this.onLogin}  variant="contained" color="primary"
                                            className={"loginButton modalButton loginStartButton buyButton2 "}>
                                        INGRESA
                                    </Button>

                                </div>





                                <div>

                                    <p className={'startLoginPTitle'}> {
                                        '¡Seguí compartiendo para ganar el premio!'
                                    } </p>



                                    <Button variant="contained" color="primary"
                                            className={"loginButton modalButton loginStartButton buyButton2 successButton "}
                                            href={"https://api.whatsapp.com/send?text=" + this.state.name +
                                            '%20quiere%20invitarte%20una%20pinta.%0A%C2%A1Registrate%20y%20prob%C3%A1%20nuestra%20Octubrefest%20edici%C3%B3n%20limitada%21%20https%3A%2F%2Fpatagonia.getviral.me%2F' + this.state.id}
                                    >
                                        COMPARTIR CON TUS AMIGOS
                                    </Button>


                                </div>
                            </SwipeableViews>
                            {!this.state.success &&
                            <div className={'bottomContainer'}>
                                <div style={{marginLeft: '5%'}} className={this.state.value === 0 ? 'pageBall ballSelected' : 'pageBall'}/>
                                <div className={this.state.value === 1 ? 'pageBall ballSelected' : 'pageBall'}/>
                                {/*<Button onClick={this.onNext} color="primary" className={'nextButton'}>

                                    {
                                        this.state.value < 6 && 'Siguiente'
                                    }
                                    {
                                        this.state.value === 6 && !this.state.submited && 'Finalizar'
                                    }
                                </Button>}

                                {this.props.login && this.state.submited && this.state.value === 6 &&
                                <CircularProgress className={'nextButton progressCircular'} />
                                }*/}

                            </div>
                            }

                        </Paper>
                    </div>
                </Interactable.View>
            </MuiThemeProvider>

        )
    }
})
